import { useCallback, useEffect, useRef, useState } from 'react';
import { SESSIONOBJECT } from '../../App';
import { ReactComponent as FileUpload } from '../../Assets/fileUpload.svg';
import { ReactComponent as NetBanking } from '../../Assets/onlineBanking.svg';
import { ReactComponent as RightArrow } from '../../Assets/right_arrow.svg';
import {
  AATERMINALEVENTS,
  logoBaseUrl,
  prefix,
  topBankPriority,
  topBanks,
} from '../../Contants';
import {
  getBankDetails,
  getBankList,
  initiateConsent,
} from '../../Services/ConsentService';
import { BANK, BankDetailsResponse } from '../../Types/ResponseTypes';
import {
  pushAAFailed,
  pushAAJourneySelected,
  pushEventToURLShortener,
  pushFipSelected,
  pushJourneyCompletedEvent,
  pushJourneyFailedEvent,
  pushNBFailed,
  pushNetBankingJourneySelected,
  pushPDFFailed,
  pushPDFJourneySelected,
} from '../../Utils/eventUtils';
import styles from './index.module.css';

enum UICOMPONENT {
  'BANKSELECTION',
  'WEBVIEW',
  'FLOWSELECTION',
}

const FipList: React.FC = () => {
  const [selectedBank, setSelectedBank] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [banksToShow, setBanksToShow] = useState<any>([]);
  const [globalBankList, setGlobalBankList] = useState<BANK[]>([]);
  const [currentComponent, setCurrentComponent] = useState(
    UICOMPONENT.BANKSELECTION
  );
  const [webviewURL, setWebviewURL] = useState('');
  const iframeRef = useRef<any>();
  const isConsentApproved = useRef(false);
  const [showAAErroMessage, setShowAAErrorMessage] = useState(false);

  const isAAJourneyFallback = useRef(false);
  const getBanksList = async () => {
    try {
      const BankListResponse = await getBankList();
      if (!BankListResponse.ok) {
        throw 'Session Timeout';
      }
      const BankListResponseBody: BANK[] = JSON.parse(
        await BankListResponse.text()
      );
      setGlobalBankList(BankListResponseBody);
      setBanksToShow(BankListResponseBody);
    } catch (e: any) {
      pushJourneyFailedEvent();
    }
  };
  const onBankSearch = (event: React.FormEvent<HTMLInputElement>) => {
    const searchedBank = globalBankList.filter((bankObject: any) => {
      return (
        bankObject.id
          .toLowerCase()
          .includes(event.currentTarget.value.toLowerCase()) ||
        bankObject.name
          .toLowerCase()
          .includes(event.currentTarget.value.toLowerCase())
      );
    });
    setBanksToShow(searchedBank);
  };
  const onAccountSelect = (fipId: string) => {
    if (!isButtonLoading) {
      setSelectedBank(fipId);
    }
  };

  useEffect(() => {
    if (SESSIONOBJECT.FIP_IDS.length >= 1) {
      setSelectedBank(SESSIONOBJECT.FIP_IDS[0]);
      setIsButtonLoading(false);
      getConsentTypeAndCreateConsent(SESSIONOBJECT.FIP_IDS[0]);
    } else {
      getBanksList();
    }
  }, []);

  const postJourneyCompleted = (status: string) => {
    if ((window as any)?.ReactNativeWebView) {
      (window as any)?.ReactNativeWebView.postMessage(
        JSON.stringify({
          eventCode: 'JourneyCompleted',
          journeyType: '',
          status: status,
        })
      );
    }
  };
  const handleJourneyCompletion = useCallback(() => {
    if (!isConsentApproved.current) {
      if (SESSIONOBJECT.AVAILABLEJOURNEYS.length > 0) {
        setCurrentComponent(UICOMPONENT.FLOWSELECTION);
      } else {
        setIsLoading(true);
        setLoadingText('Hang Tight! Taking you back to the app now… ');
        pushJourneyFailedEvent();
      }
    } else {
      setIsLoading(true);
      setLoadingText('Hang Tight! Taking you back to the app now… ');
      pushJourneyCompletedEvent();
    }
  }, []);

  const handleEventCallback = useCallback(
    (event: any) => {
      const { data } = event;

      const eventCode = data.eventCode;

      pushEventToURLShortener(data.eventCode, data.errorMessage);
      if (eventCode) {
        // switch (SESSIONOBJECT.JOURNEY_TYPE) {
        //   case 'AA':
        //     if (eventCode === 'IFRAME_SUCCESS') {
        //       setIsLoading(false);
        //     }
        //     break;
        //   case 'PDF':
        //     if (eventCode === 'PDF_COMPONENT_RENDER') {
        //       setIsLoading(false);
        //     }
        //     break;
        //   case 'NETBANKING':
        //     if (eventCode === 'NETBANKING_COMPONENT_RENDERED') {
        //       setIsLoading(false);
        //     }
        //     break;
        // }

        if (AATERMINALEVENTS.SUCCESS.includes(eventCode)) {
          isConsentApproved.current = true;
          handleJourneyCompletion();
        }
        if (AATERMINALEVENTS.FAILURE.includes(eventCode)) {
          switch (SESSIONOBJECT.JOURNEY_TYPE) {
            case 'AA': {
              pushAAFailed();
              isAAJourneyFallback.current = true;
              break;
            }
            case 'PDF': {
              pushPDFFailed();
              break;
            }
            case 'NETBANKING': {
              pushNBFailed();
              break;
            }
          }
          handleJourneyCompletion();
        }
      }
    },
    [handleJourneyCompletion]
  );
  useEffect(() => {
    window.addEventListener('message', handleEventCallback);
  }, [handleEventCallback]);

  const createConsent = async (journeyType: string | undefined) => {
    let currentJourney;
    if (journeyType) {
      currentJourney = journeyType;
      SESSIONOBJECT.AVAILABLEJOURNEYS = SESSIONOBJECT.AVAILABLEJOURNEYS.filter(
        (journey) => journey !== journeyType
      );
    } else {
      currentJourney = SESSIONOBJECT.AVAILABLEJOURNEYS.shift();
    }

    setIsLoading(true);
    try {
      SESSIONOBJECT.JOURNEY_TYPE = currentJourney!;
      setLoadingText(
        'Hang Tight! You will be redirected to next screen shortly...'
      );

      const consentResponse = await initiateConsent({
        groupId: SESSIONOBJECT.GROUPID!,
        fip: SESSIONOBJECT.FIP_ID!,
        journeyType: SESSIONOBJECT.JOURNEY_TYPE!,
      });
      if (!consentResponse.ok) {
        throw consentResponse.status;
      }
      const journeyURL = await consentResponse.text();

      setWebviewURL(journeyURL);
      switch (SESSIONOBJECT.JOURNEY_TYPE) {
        case 'AA':
          pushAAJourneySelected();
          break;
        case 'NETBANKING':
          pushNetBankingJourneySelected(isAAJourneyFallback.current);
          break;
        case 'PDF':
          pushPDFJourneySelected(isAAJourneyFallback.current);
          break;
      }
      setCurrentComponent(UICOMPONENT.WEBVIEW);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (e) {
      handleEventCallback({
        data: { eventCode: 'CONSENT_APPROVED_FAILED' },
      });
      setIsLoading(false);
    } finally {
    }
  };

  const getConsentTypeAndCreateConsent = async (
    selected: string | undefined
  ) => {
    if (selected) {
      SESSIONOBJECT.FIP_ID = selected!;
    } else {
      SESSIONOBJECT.FIP_ID = selectedBank!;
      pushFipSelected();
    }
    setIsButtonLoading(true);
    try {
      const BankDetailsResponse = await getBankDetails();
      const BankDetailsResponseBody: BankDetailsResponse = JSON.parse(
        await BankDetailsResponse.text()
      );

      if (BankDetailsResponseBody.enabled) {
        setShowAAErrorMessage(true);
        SESSIONOBJECT.JOURNEY_TYPE = 'AA';
        SESSIONOBJECT.AVAILABLEJOURNEYS.push('AA');
      }
      if (BankDetailsResponseBody.netbankingEnabled) {
        SESSIONOBJECT.JOURNEY_TYPE = 'NETBANKING';
        SESSIONOBJECT.AVAILABLEJOURNEYS.push('NETBANKING');
      }
      if (BankDetailsResponseBody.pdfJourneyEnabled) {
        SESSIONOBJECT.JOURNEY_TYPE = 'PDF';
        SESSIONOBJECT.AVAILABLEJOURNEYS.push('PDF');
      }
      if (BankDetailsResponseBody.enabled) {
        createConsent(undefined);
      } else {
        setCurrentComponent(UICOMPONENT.FLOWSELECTION);
        setIsButtonLoading(false);
      }
    } catch (e) {
      setIsButtonLoading(false);
    }
  };

  const renderCurrentComponent = () => {
    switch (currentComponent) {
      case UICOMPONENT.BANKSELECTION:
        return (
          <>
            {globalBankList.length > 0 ? (
              <div className={styles.fipListRoot}>
                <div className={styles.fipListBody}>
                  <div className={styles.header}>
                    <text className={styles.textHeader}>Select your bank</text>
                    <text className={styles.textDescription}>
                      Choose your primary bank
                    </text>
                  </div>
                  <div className={styles.searchContainer}>
                    <svg
                      viewBox='64 64 896 896'
                      focusable='false'
                      data-icon='search'
                      width='1em'
                      height='1em'
                      fill='gray'
                      aria-hidden='true'
                    >
                      <path d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z'></path>
                    </svg>
                    <input
                      className={styles.bankInputSearch}
                      onInput={(event: React.FormEvent<HTMLInputElement>) => {
                        onBankSearch(event);
                      }}
                      placeholder='Search your bank'
                    />
                  </div>
                  {banksToShow.filter((bank: BANK) =>
                    topBanks.includes(bank.id)
                  ).length > 0 ? (
                    <div className={styles.secondaryHeader}>Popular banks</div>
                  ) : (
                    <></>
                  )}

                  <div className={styles.popularFipsContainer}>
                    {banksToShow
                      .filter((bank: BANK) => topBanks.includes(bank.id))
                      .sort((a: BANK, b: BANK) => {
                        return topBankPriority[a.id] - topBankPriority[b.id];
                      })
                      .map((bank: BANK) => (
                        <div
                          key={'popularBank' + bank.id}
                          onClick={() => onAccountSelect(bank.id)}
                          className={`${styles.popularFipsCard} noSelect ${
                            selectedBank === bank.id
                              ? styles.selectedPopularFipsCard
                              : ''
                          }`}
                        >
                          <img
                            alt=''
                            style={{
                              width: 32,
                              height: 32,
                              objectFit: 'contain',
                            }}
                            src={logoBaseUrl + bank.id + '.jpeg'}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = `${prefix}/defaultBank.png`;
                            }}
                          />
                          <text
                            className={`${styles.bankName} ${
                              selectedBank === bank.id
                                ? styles.selectedBankName
                                : ''
                            }`}
                            style={{ color: 'var(--text-color)' }}
                          >
                            {bank.name}
                          </text>
                        </div>
                      ))}
                  </div>
                  <div className={styles.allBanksHeader}>
                    <text className={styles.secondaryHeader}>All banks</text>
                  </div>

                  {banksToShow.map((bank: BANK) => (
                    <div
                      key={bank.id}
                      onClick={() => onAccountSelect(bank.id)}
                      className='noSelect'
                    >
                      <div className={styles.allFipsContainer}>
                        <img
                          alt=''
                          style={{
                            width: 32,
                            height: 32,
                            objectFit: 'contain',
                          }}
                          src={logoBaseUrl + bank.id + '.jpeg'}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = `${prefix}/defaultBank.png`;
                          }}
                        />
                        <div
                          style={{
                            color: 'var(--text-color)',
                            fontWeight: bank.id === selectedBank ? 600 : 400,
                          }}
                        >
                          {bank.name}
                        </div>
                        {bank.id === selectedBank ? (
                          <img
                            alt=''
                            style={{
                              width: 16,
                              height: 16,
                              objectFit: 'contain',
                              marginLeft: 'auto',
                            }}
                            src={prefix + '/checked.png'}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        style={{ height: 1, background: 'gray', opacity: 0.4 }}
                      ></div>
                    </div>
                  ))}
                </div>
                <button
                  className={styles.primaryButton}
                  disabled={selectedBank === undefined || isButtonLoading}
                  onClick={() => {
                    getConsentTypeAndCreateConsent(undefined);
                  }}
                >
                  {!isButtonLoading ? 'Continue' : 'Loading...'}
                </button>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 'var(--root-height)',
                }}
              >
                <div className='loader'></div>
              </div>
            )}
          </>
        );
      case UICOMPONENT.WEBVIEW:
        return (
          <>
            {/* {SESSIONOBJECT.TENANTID?.includes('bob_card') ? (
              <div
                style={{
                  textAlign: 'right',
                  margin: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleEventCallback({
                    data: { eventCode: 'CONSENT_APPROVED_FAILED' },
                  });
                }}
              >
                {SESSIONOBJECT.AVAILABLEJOURNEYS.length > 0 ? (
                  <u>Try with alternate method</u>
                ) : (
                  <u>Go back</u>
                )}
              </div>
            ) : (
              <></>
            )} */}

            <iframe
              title='AA Journey'
              ref={iframeRef}
              src={webviewURL}
              style={{
                width: '100%',
                height: 'var(--root-height)',
                border: 'none',
              }}
            />
          </>
        );
      case UICOMPONENT.FLOWSELECTION:
        return (
          <>
            <div className={styles.flowSelectionContainer}>
              {showAAErroMessage ? (
                <>
                  <span style={{ color: 'var(--text-color)' }}>
                    Oops! We couldn’t complete the verification, or your bank is
                    not currently supported by the AA
                  </span>
                  <h2>Try an Alternate Method</h2>
                </>
              ) : (
                <h2>Choose a method to proceed with</h2>
              )}

              <div className={styles.flowSelection} style={{ marginTop: '5%' }}>
                {SESSIONOBJECT.AVAILABLEJOURNEYS.includes('NETBANKING') ? (
                  <div
                    className={styles.method}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      createConsent('NETBANKING');
                    }}
                  >
                    <div>
                      <div className={styles.methodIcon}>
                        <NetBanking
                          className={styles.methodIcon}
                          style={{ width: 40 }}
                        />
                      </div>
                      <div className={styles.methodInfo}>
                        <h2 className={styles.flowSelectionHeader}>
                          Net banking
                        </h2>
                        <span className={styles.verificationType}>
                          To use this method, log in to your net banking account
                        </span>
                      </div>
                    </div>
                    <div className={styles.methodIcon}>
                      <RightArrow className={styles.rightIcon} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {SESSIONOBJECT.AVAILABLEJOURNEYS.includes('PDF') ? (
                  <div
                    className={styles.method}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '3%',
                    }}
                    onClick={() => {
                      createConsent('PDF');
                    }}
                  >
                    <div className={styles.fallbackCard}>
                      <FileUpload className={styles.methodIcon} />

                      <div className={styles.methodInfo}>
                        <h2 className={styles.flowSelectionHeader}>
                          {SESSIONOBJECT.TENANTID === 'bob_cards_fiu_uat' ||
                          SESSIONOBJECT.TENANTID === 'bob_card_fiu_prod'
                            ? 'Direct Submission'
                            : 'PDF Upload'}
                        </h2>
                      </div>
                      <span className={styles.verificationType}>
                        Upload your bank statement for quick verification
                      </span>
                    </div>

                    <div className={styles.methodIcon}>
                      <RightArrow className={styles.rightIcon} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div style={{ position: 'relative', height: '100%' }}>
        {isLoading ? (
          <div
            style={{
              height: 'var(--root-height)',
              boxSizing: 'border-box',
              width: '100%',
              padding: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              color: 'var(--text-color)',
              position: 'absolute',
              top: 0,
              zIndex: '10',
              background: 'var(--primary-backgroud-color)',
            }}
          >
            <div className='loader'></div>
            <div></div>
            Loading....
            <br />
            <br />
            {loadingText}
          </div>
        ) : (
          <></>
        )}

        {renderCurrentComponent()}
      </div>
    </>
  );
};

export default FipList;
